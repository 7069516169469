<template>
	<div class="discuss-component">
		<div class="discuss-body">
			<div class="card discuss">
				<div class="card-header">
					<div class="type">{{ serial }}.论述题</div>
					<div class="remark">(共{{ details.questionNumber }}道题，合计{{ details.totalScores }}分)</div>
					<div class="tag" v-if="examsQuestion.questionLabel">
						<template v-if="examsQuestion.questionLabel == 'organize'">组织碳</template>
						<template v-if="examsQuestion.questionLabel == 'product'">产品碳</template>
					</div>
					<div class="score">(本题分数：{{ examsQuestion.totalScores }}分)</div>
				</div>
				<div class="card-main">
					<div class="question">
						<div class="question-title ql-editor" v-html="examsQuestion.examsQuestion.questionTitle"></div>
						<div
							class="question-tip ql-editor"
							v-html="examsQuestion.questionContent && formatRichText(examsQuestion.questionContent)"
							@click="imageChagange($event)"
						></div>
						<div class="question-editor">
							<editor @on-change="changeEditor" :value="content" :height="400" />
							<!-- <editor-plus @changeEditor="changeEditor" :content="content" :height="400" /> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-image-viewer v-if="showViewer" :z-index="999999" :initial-index="previewIndex" :appendToBody="false" :on-close="closeViewer" :url-list="srcListView" />
	</div>
</template>

<script>
import { verifyEditorIsEmpty } from '@/utils/utils.js';
export default {
	name: 'Discuss', // 论述题
	components: {
		ElImageViewer: () => import('element-ui/packages/image/src/image-viewer')
	},
	props: {
		examsQuestionListIndex: {
			type: [Number, String],
			default: -1
		},
		examsQuestion: {
			type: [Object],
			default() {
				return {};
			}
		},
		details: {
			type: [Object],
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			// content: '<p><img src="https://oss.cn-north-3.inspurcloudoss.com/eccloud/094e37b1852f43fcba83c8f3127238a5"></p><p>123</p>'
			content: '',
			showViewer: false,
			srcListView: [{}],
			previewIndex: 0
		};
	},
	computed: {
		serial() {
			return this.examsQuestionListIndex + 1;
		}
	},
	created() {
		let index = this.examsPaperAnswerCache.findIndex((v) => v.id == this.examsQuestion.questionId);
		this.content = this.examsPaperAnswerCache[index].answerContent || '';
	},
	methods: {
		changeEditor({ html, text, quill }) {
			console.log('changeditor', html, text, quill);
			if (!verifyEditorIsEmpty(html)) {
				this.content = html;
			} else {
				this.content = null;
			}
			let examsPaperAnswerCache = JSON.parse(JSON.stringify(this.examsPaperAnswerCache)) || [];
			let index = examsPaperAnswerCache.findIndex((v) => v.id == this.examsQuestion.questionId);
			examsPaperAnswerCache[index].answerContent = this.content;
			this.$store.commit('changeExamsPaperAnswerCache', examsPaperAnswerCache);
		},
		//富文本渲染文本点击
		imageChagange(e) {
			if (e.target.nodeName === 'IMG' || e.target.nodeName == 'img') {
				//判断是否图片标签
				const img = e.target.currentSrc; //获取点击图片地址
				this.onPreview([img]);
			}
		},
		//开启富文本图片预览
		onPreview(img) {
			this.srcListView = img;
			this.showViewer = true;
			document.documentElement.style.overflowY = 'hidden';
		},
		//关闭富文本渲染文本图片预览
		closeViewer() {
			this.showViewer = false;
			document.documentElement.style.overflowY = 'auto';
		}
	}
};
</script>

<style lang="scss" scoped>
.discuss-component {
	flex: 1;
	display: flex;
	flex-direction: column;
	.discuss-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		.card {
			flex: 1;
			display: flex;
			flex-direction: column;
			width: 970px;
			// height: 700px;
			background: #ffffff;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
			border-radius: 4px 4px 4px 4px;
			border: 1px solid;
			border-image: linear-gradient(360deg, rgba(212, 222, 233, 1), rgba(255, 255, 255, 1)) 1 1;
			box-sizing: border-box;
			margin-top: 30px;
			padding-bottom: 30px;
			box-sizing: border-box;
			.card-header {
				// width: 970px;
				height: 62px;
				background: linear-gradient(90deg, #dcdcdc 0%, #c2c2c2 100%);
				border-radius: 4px 4px 0px 0px;
				box-sizing: border-box;
				padding: 0 30px;
				display: flex;
				.type {
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					font-size: 22px;
					color: #111111;
					line-height: 62px;
				}
				.remark {
					margin-left: 10px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #666666;
					line-height: 62px;
				}
				.score {
					margin-left: auto;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #666666;
					line-height: 62px;
				}
				.tag {
					align-self: center;
					border-color: #019a7f;
					background-color: #019a7f;
					color: #ffffff;
					padding: 0 7px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					height: 24px;
					border-radius: 5px;
					margin-left: 10px;
				}
			}
			.card-main {
				.question {
					display: flex;
					flex-direction: column;
					.question-title {
						box-sizing: border-box;
						padding: 35px 30px 0;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 20px;
						color: #000000;
						// line-height: 20px;
					}
					.question-tip {
						margin-top: 20px;
						box-sizing: border-box;
						padding: 0 30px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: #163638;
						line-height: 32px;
					}
					.question-editor {
						margin-top: 10px;
						padding: 0 30px;
						box-sizing: border-box;
					}
				}
			}
		}
	}
}
</style>