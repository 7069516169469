<template>
	<div class="estimate-component">
		<div class="estimate-body">
			<div class="card estimate">
				<div class="card-header">
					<div class="type">{{ serial }}.判断题</div>
					<div class="remark">(共{{ details.questionNumber }}道题，合计{{ details.totalScores }}分)</div>
					<div class="tag" v-if="examsQuestion.questionLabel">
						<template v-if="examsQuestion.questionLabel == 'organize'">组织碳</template>
						<template v-if="examsQuestion.questionLabel == 'product'">产品碳</template>
					</div>
					<div class="score">(本题分数：{{ examsQuestion.totalScores }}分)</div>
				</div>
				<div class="card-main">
					<div class="question">
						<div class="question-title ql-editor" v-html="examsQuestion.examsQuestion.questionTitle"></div>
						<div class="question-single">
							<el-checkbox-group v-model="value" @change="handleChange">
								<el-checkbox :label="item.id" v-for="(item, index) in examsQuestion.examsQuestionOptionList" :key="item.id">
									<span class="label">{{ index | estimateLetterFilter }}{{ `. ` }}{{ item.content }}</span>
								</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Estimate', //判断题
	props: {
		examsQuestionListIndex: {
			type: [Number, String],
			default: -1
		},
		examsQuestion: {
			type: [Object],
			default() {
				return {};
			}
		},
		details: {
			type: [Object],
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			value: []
		};
	},
	computed: {
		serial() {
			return this.examsQuestionListIndex + 1;
		}
	},
	created() {
		let index = this.examsPaperAnswerCache.findIndex((v) => v.id == this.examsQuestion.questionId);
		this.value = this.examsPaperAnswerCache[index].answerOption ? [this.examsPaperAnswerCache[index].answerOption] : [];
	},
	methods: {
		handleChange(e) {
			console.log('handleChange-e: ', e);
			let examsPaperAnswerCache = JSON.parse(JSON.stringify(this.examsPaperAnswerCache)) || [];
			let index = examsPaperAnswerCache.findIndex((v) => v.id == this.examsQuestion.questionId);
			if (e.length > 1) {
				this.value = [e[e.length - 1]];
			}
			if (!this.value.length) {
				examsPaperAnswerCache[index].answerOption = null;
			} else {
				examsPaperAnswerCache[index].answerOption = this.value[0];
			}
			this.$store.commit('changeExamsPaperAnswerCache', examsPaperAnswerCache);
		}
	}
};
</script>

<style lang="scss" scoped>
.estimate-component {
	flex: 1;
	display: flex;
	flex-direction: column;
	.estimate-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		.card {
			flex: 1;
			display: flex;
			flex-direction: column;
			width: 970px;
			// height: 700px;
			background: #ffffff;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
			border-radius: 4px 4px 4px 4px;
			border: 1px solid;
			border-image: linear-gradient(360deg, rgba(212, 222, 233, 1), rgba(255, 255, 255, 1)) 1 1;
			box-sizing: border-box;
			margin-top: 30px;
			padding-bottom: 30px;
			box-sizing: border-box;
			.card-header {
				// width: 970px;
				height: 62px;
				background: linear-gradient(90deg, #dcdcdc 0%, #c2c2c2 100%);
				border-radius: 4px 4px 0px 0px;
				box-sizing: border-box;
				padding: 0 30px;
				display: flex;
				.type {
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					font-size: 22px;
					color: #111111;
					line-height: 62px;
				}
				.remark {
					margin-left: 10px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #666666;
					line-height: 62px;
				}
				.score {
					margin-left: auto;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #666666;
					line-height: 62px;
				}
				.tag {
					align-self: center;
					border-color: #019a7f;
					background-color: #019a7f;
					color: #ffffff;
					padding: 0 7px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					height: 24px;
					border-radius: 5px;
					margin-left: 10px;
				}
			}
			.card-main {
				.question {
					display: flex;
					flex-direction: column;
					.question-title {
						box-sizing: border-box;
						padding: 35px 30px 0;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 20px;
						color: #000000;
						// line-height: 20px;
					}
					.question-single {
						box-sizing: border-box;
						padding-left: 30px;
						margin-top: 35px;
						::v-deep {
							.el-checkbox-group {
								display: flex;
								flex-direction: column;
								.el-checkbox {
									display: flex;
									align-items: center;
									&:not(:first-child) {
										margin-top: 40px;
									}
									.el-checkbox__input {
										display: flex;
										align-items: center;
										&.is-focus {
											.el-checkbox__inner {
												border-color: #dcdfe6;
											}
										}
										&.is-checked {
											.el-checkbox__inner {
												border-color: #008f50;
												background-color: transparent;
												&::after {
													background-color: #008f50;
												}
											}
										}
										.el-checkbox__inner {
											border-radius: 50%;
											width: 20px;
											height: 20px;
											display: flex;
											justify-content: center;
											align-items: center;
											// box-sizing: border-box;
											&:hover {
												border-color: #008f50;
											}
											&::after {
												box-sizing: border-box;
												content: '';
												height: 10px;
												left: initial;
												position: absolute;
												top: initial;
												width: 10px;
												border-radius: 50%;
												background-color: #dcdfe6;
												transform: none;
											}
										}
										.el-checkbox__original {
										}
									}
									.el-checkbox__label {
										font-family: PingFang SC-Medium;
										font-weight: 500;
										font-size: 16px;
										color: #000000;
										line-height: 16px;
									}
								}
							}
						}
						.label {
						}
						.label {
						}
					}
				}
			}
		}
	}
}
</style>